@import-normalize;

body,
* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  font-size: 14px;
}

* {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5em;
  color: inherit;
}